<template>
  <div>
    <div class="s-orders-nav">
      <div class="s-flex-basis-25">
        <button class="s-orders-btn ml-3 pl-3 pr-3" @click="$router.go(-1)">
          返回
        </button>
      </div>
      <div class="text-center s-flex-basis-50">修改數量</div>
    </div>
    <p v-if="fetching"><Loading /></p>
    <template v-if="isClosed && isOverdueDeadline">
      <div class="s-mb-6 s-space-y-3 text-center">
        <Status :class="'g-text-secondary'" :icon="'clock'" :message="'此團已結單'" />
        <p v-html="statusInfo"></p>
      </div>
      <div class="s-space-y-8 s-mb-8" v-if="product && groupBuying">
        <DescriptionList title="商品名稱">{{ product.title }}</DescriptionList>
        <DescriptionList title="團購編號">{{ groupBuying.groupbuying_number }}</DescriptionList>
      </div>
      <a class="s-btn s-btn-primary" @click="$router.go(-1)">返回</a>
    </template>
    <Form v-else ref="form" :action="submit">
      <div class="s-mb-2" v-if="coverImage">
        <img class="s-rounded-lg s-w-full" :src="coverImage">
      </div>
      <h2 class="s-text-xl s-text-gray-darker s-font-semibold s-mb-2">{{ product.title }}</h2>
      <div v-if="groupBuying.type !== 3">
        <dl class="s-flex">
          <dt>商品單價：</dt>
          <dd>{{ spec.price }} 元</dd>
        </dl>
        <dl class="s-flex">
          <dt>結單時間：</dt>
          <dd>{{ endAt }}</dd>
        </dl>
        <dl class="s-flex">
          <dt>取貨方式：</dt>
          <dd>{{ deliveryMethods }}</dd>
        </dl>
        <dl class="s-flex">
          <dt>付款方式：</dt>
          <dd>下單後立即付款</dd>
        </dl>
        <dl class="s-flex">
          <dt>出貨日期：</dt>
          <dd>{{ groupBuying.delivery_shipping_day }}</dd>
        </dl>
      </div>
      <div v-else><!--- Type = 3 的門市庫存品類型團購 --->
        <dl class="s-flex">
          <dt>商品原價：</dt>
          <dd>{{ groupBuying.groupbuying_sku.original_price }} 元</dd>
        </dl>
        <dl class="s-flex">
          <dt>商品售價：</dt>
          <dd v-if="groupBuying.groupbuying_sku.is_promotion">{{ groupBuying.groupbuying_sku.package_type }}</dd>
          <dd v-else>{{ groupBuying.groupbuying_sku.price }} 元</dd>
        </dl>
        <dl class="s-flex">
          <dt>取貨方式：</dt>
          <dd>門市自取</dd>
        </dl>
        <dl class="s-flex">
          <dt>付款方式：</dt>
          <dd>下單後門市付款</dd>
        </dl>
        <dl class="s-flex">
          <dt>取貨期限：</dt>
          <dd>{{ branchEndAt }}</dd>
        </dl>
      </div>
      <div class="s-pb-4 s-mb-4 s-border-b s-border-gray-lighter" />
      <h3 class="s-text-gray-darker s-font-semibold s-mb-2">商品說明</h3>
      <div class="s-mb-2" v-html="description" />
      <div class="s-mb-2" v-if="productImages.length">
        <img class="s-w-full" :src="image" :key="i" v-for="(image, i) in productImages">
      </div>
      <div>
        <dl v-if="group" class="s-flex">
          <dt class="s-whitespace-nowrap">跟團群組：</dt>
          <dd>{{ group.name }}</dd>
        </dl>
        <dl class="s-flex">
          <dt class="s-whitespace-nowrap">團購編號：</dt>
          <dd>{{ groupBuying.groupbuying_number }}</dd>
        </dl>
      </div>
      <div class="s-product-form">
        <Counter
          class="s-mb-4"
          label="下單數量"
          name="counter"
          :min="min"
          :max="max"
          :step="basis"
          :help="`下單數量最多 ${max} 個，最少 ${min} 個`"
          help-class="s-text-danger"
          v-model="quantity"
          :disabled="groupBuying.type !== 3"
        />
        <div class="s-flex s-items-center">
          <dl class="s-flex-shrink-0 s-flex s-items-center s-mr-2">
            <dt class="s-mr-2">金額</dt>
            <dd class="s-text-xl s-text-secondary s-price">$ {{ price }}</dd>
          </dl>
          <button v-if="groupBuying" class="s-btn s-btn-primary" type="submit" :disabled="groupBuying.type !== 3">
            {{ (groupBuying.type !== 3) ? '不支援修改': '確定修改' }}
          </button>
        </div>
      </div>
    </Form>
  </div>
</template>

<script>
  import moment from 'moment'
  import { mapActions, mapState } from 'vuex'
  import Loading from '@/components/Page/Liff/Shared/Loading'
  import Form from '@/components/Page/Liff/Form/Form'
  import Counter from '@/components/Page/Liff/Form/Counter'
  import fetchGroupBuying from '@/mixins/liff/groupBuying/fetchGroupBuying'
  import Status from '@/components/Page/Liff/GroupBuying/Status'
  import DescriptionList from '@/components/Page/Liff/GroupBuying/DescriptionList'

  export default {
    mixins: [fetchGroupBuying],
    components: { Loading, Form, Counter, Status, DescriptionList },
    inject: ['$alert'],
    data: () => ({
      quantity: 1,
      order: [{
        items: [{
          product: {}
        }]
      }],
      groupBuying: {
        groupbuying_sku: {
          price: 0,
          is_promotion: false,
        },
      },
    }),
    computed: {
      ...mapState({
        liffInfo: (state) => state.liffGroupBuying.liffInfo,
      }),
      coverImage () {
        if (!this.order.items) return null;
        const images = this.order.items[0]?.product?.images || [{}]
        return images[0].pic_url
      },
      product () {
        return (this.order.items) ? this.order.items[0] : {};
      },
      spec () {
        return (this.order.items) ? this.order.items[0] : {};
      },
      min () {
        return this.groupBuying.once_quantity || 1
      },
      max () {
        if (this.isClosed && !this.isOverdueDeadline && this.order.items) {
          return this.order.items.length;
        }
        return this.groupBuying.once_max_quantity || 10
      },
      basis () {
        return this.groupBuying.once_increase_quantity || 1
      },
      price () {
        let sku = this.groupBuying.groupbuying_sku;
        if (sku.is_promotion) {
          // 之後要封裝成一個可以共用的計算器
          if (sku.promotion_config.type === 'combination') {
            let remainder = this.quantity % sku.promotion_config.config.quantity;
            let multiplier = (this.quantity - remainder) / sku.promotion_config.config.quantity;
            return multiplier * sku.promotion_config.config.price + remainder * sku.price;
          }
        }
        return sku.price * this.quantity;
      },
      endAt () {
        return moment(new Date()).format("YYYY/MM/DD HH:mm");
      },
      branchEndAt() {
        return moment(this.groupBuying.end_at).format("YYYY/MM/DD");
      },
      description () {
        const description = this.product.description || ''

        return description.replace(/\n/g, '<br />')
      },
      deliveryMethods () {
        const methods = this.groupBuying.delivery_label || []

        return methods.join('、')
      },
      isClosed() {
        if (!this.groupBuying.status) return false;
        let status = +this.groupBuying.status.slice(0, 1);
        return status !== 1 && status !== 4;
      },
      isOverdueDeadline() {
        if (! this.order.deadline_at) return false;

        let now = new Date();
        let deadline = new Date(this.order.deadline_at);

        return deadline <= now;
      },
      statusInfo () {
        if (this.groupBuying && this.groupBuying.type === 3) {
          return "尚未跟上本次團購，可於群組內告知小編<br>因團購商品為不定時上架，將依情況開團呦";
        } else {
          return '請與客服人員聯繫<br>' + (this.liffInfo.liff_extra?.service_phone ?? '');
        }
      },
    },
    methods: {
      ...mapActions({
        show: 'liffGroupBuying/getOrderV2',
        update: 'liffGroupBuying/updateOrderV2',
      }),
      async fetch () {
        this.order = []
        const  data  = await this.show(
          { id: this.$route.params.id }
          )
        this.order = data;
        this.groupBuying = this.order.groupbuying_order.groupbuying;
        this.quantity = this.order.items.length ?? 1;
      },
      async submit() {
        try {
          await this.update({
            id: this.order.id,
            quantity: this.quantity,
          });

          this.$router.push({
            name: "LiffGroupBuyingOrdersV2Detail",
            params: { id: this.order.id, status: "processing" },
          });
        } catch ({ message }) {
          return this.$alert(message);
        }
      }
    }
  }
</script>

<style scoped>
  .s-orders-nav {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin-left: -1rem;
    margin-right: -1rem;
    display: flex;
    padding: 0.75rem 0;
    border-bottom: solid 1px var(--s-gray-lighter);
    background-color: white;
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
  }

  .s-orders-btn {
    color: var(--s-gray);
    border: 1px solid var(--s-gray);
    text-decoration: none;
    border-radius: 8px;
    font-size: 14px;
    line-height: 1.5;
    padding: 2px 6px;
  }

  .s-flex-basis-25 {
    flex-basis: 25%;
  }

  .s-flex-basis-50 {
    flex-basis: 50%;
  }

  .s-product-form {
    position: sticky;
    bottom: 0;
    background-color: var(--s-white);
    padding: 0.5rem 0;
    margin-bottom: -1.25rem;
  }

  .s-price {
    min-width: 5rem;
  }
</style>
